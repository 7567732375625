import React from 'react';
import '../resources/css/style.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactBody from '../components/page/Contact';
import pages from '../resources/pages.json';

const ContactPage = () => (
  <Layout>
    <SEO title={pages.contact.title} description={pages.contact.description} />
    <ContactBody />
  </Layout>
);

export default ContactPage;
